/* AddressList.css */

.address-list-check {
    padding: 20px;
    /* margin: 0 auto;
    width: 100%;
    max-width: 50%; */

}

.address-list-container {
    padding: 20px;
}

.address-item {
    padding: 20px;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
}

.address-actions {
    position: absolute;
    top: 10px;
    right: 10px;
}

.add-address-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.add-address-button,
.proceed-to-pay-button {
    margin-top: 20px;
}
.proceed-to-pay-button{
    display: flex;
    align-items: center;
    justify-content: end;
}

@media (max-width: 768px) {
    .address-list-check {
        padding: 10px;
        /* margin: 0 auto;
        width: 100%;
        max-width: 70%; */
    }

    .address-list-container {
        padding: 10px;
    }
}