/* MemeFabricFooter.css */
.MemeFabric-footer {
    background-color: #00247D;
    color: #fff;
    padding: 30px 0;
  }
  
  .MemeFabric-footer-content {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
  }
  
  .MemeFabric-footer-section {
    flex: 1;
  }
  
  .MemeFabric-footer-section h3 {
    margin-bottom: 20px;
  }
  
  .MemeFabric-footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .MemeFabric-footer-section ul li {
    margin-bottom: 10px;
  }
  
  .MemeFabric-footer-bottom {
    text-align: center;
    margin-top: 20px;
  }
  
  .MemeFabric-footer-bottom p {
    font-size: 12px;
  }
  li{
      font-size: 12px;
  }
  