.amazon-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
 /* height: 100vh; */
 padding: 7% ;
}

.amazon-login-box {
  width: 350px;
  padding: 20px;
  background-color: #fff;
  position: relative; /* Add this line */
  z-index: 1; /* Add this line */
}

.amazon-login-box h2 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 3px;
  background-color: #CF142B;
  color: #111;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background-color: #CF142B;
}

hr {
  margin: 20px 0;
}

p {
  margin-bottom: 10px;
}

/* Styling for error messages */
.error-message {
  color: #c0392b; /* Red color for error messages */
  font-size: 14px;
}

.global-error-message {
  color: #c0392b; /* Red color for error messages */
  font-size: 16px;
  margin-top: 10px;
}
