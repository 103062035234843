/* ViewCartPage.css */

/* Container for the entire view cart page */
.view-cart-container {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  background-color: #EAEDED;
}

/* Styles for the left section containing cart items */
.left-section {
  flex: 1; /* Take up remaining space */
}

/* Styles for the right section containing cart summary and checkout button */
.right-section {
  width: 300px; /* Fixed width for the right section */
  margin-left: 20px; /* Add margin between sections */
}

/* Styles for cart items container */
.cart-items-container {
    overflow-y: auto;
    margin-bottom: 20px;
    background-color: #FFF;
    overflow: auto;
    padding: 20px 0 15px 0;
}

/* Styles for each cart item */
.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd; /* Add border between cart items */
}

/* Styles for product image */
.product-image {
  margin-left: 15px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
}

/* Styles for product details */
.product-details {
  flex: 1;
  line-height: 1.5vh;
}

/* Styles for quantity selector */
.quantity-selector {
  margin-top: 10px;
}

/* Styles for options container */
.options {
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding-right: 10px; /* Add padding to separate from border */
  font-size: 12px;
}

/* Styles for individual options */
.option {
  margin-right: 5px;
  color: #0066c0; /* Amazon blue color */
  cursor: pointer;
}

.option:hover {
  text-decoration: underline;
}

/* Styles for cart summary */
.cart-summary {
  padding: 20px;
  background-color: white;
  margin-right: 2px;

}

/* Styles for checkout button */
.proceed-to-checkout-btn {
  margin-top: 5px;
  width: 100%;
  padding: 10px;
  background-color: #CF142B; /* Amazon's primary button color */
  color: #111; /* Amazon's font color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: Arial, sans-serif; /* Use a fallback font */
}

.proceed-to-checkout-btn:hover {
  background-color: #ddb347; /* Darken the button color on hover */
}
.cart-product-name{
  font-size: 16px;
  font-weight: 500;
}
.product-price{
  margin-top: -80px;
  margin-right: 15px;
}
.notes{
  font-size: 12px;
}
.cart-items-container-notes{
  margin-bottom: 20px;
  background-color: #FFF;
  overflow: auto;
  padding: 20px 0 15px 0;
  margin-top: 15px;
}
.address-list {
  padding: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.no-products-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-products {
  text-align: center;
}

.no-products-image {
  margin-top: 3vh;
  width: 100px;
  height: 100px;
}

.no-product-title {
  margin-top: 1.5vh;
  font-size: 18px;
  margin-bottom: 10px;
}

.no-product-message {
  font-size: 16px;
  margin-bottom: 20px;
  color: lightgray;
}

.add-from-wishlist-btn {
  padding: 10px 20px;
  font-size: 14px;
  border:1px solid  #ddb347;
  color: #ddb347;
  cursor: pointer;
  background-color: #FFF;
  margin-bottom: 3vh;
}

.add-from-wishlist-btn:hover {
  background-color: #ddb347;
  color: white;
}
