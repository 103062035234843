/* .checkout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .amount-input {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    width: 200px;
    text-align: center;
  }
  
  .checkout-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #CF142B;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  */
  .checkout-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;
    padding: 36px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  }
  /* .container{
    padding: 10em;
  } */
  .payment-element {
    margin-bottom: 20px;
    width: 100%;
  }
  .StripeElement{
    width: 100%;
    padding: 23px;
  }
  .checkout-button {
    padding: 10px 20px;
    background-color: #6772e5;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .checkout-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .error-message {
    color: #e25950;
    margin-top: 10px;
  }
  
  