.product-list-container {
  display: flex;
  white-space: nowrap; 
}

/* Styles for the product grid */
.home-product-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

/* Styles for each product card */
.home-product-card {
  width: calc(20% - 20px); /* 25% width with 20px spacing between cards */
  margin: 10px; /* Adjust margin between rows */
  background-color: #fff;
}

/* Styles for product images */
.home-product-img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

/* Styles for product details */
.home-product-details {
  padding: 10px;
}

/* Styles for product name */
.home-product-name {
  font-size: 14px;
  font-weight: 500;
}

/* Styles for product price */
.home-product-price {
  margin-top: -16px;
  font-size: 16px;
  font-weight: bold;
  color: #212121;
}

.home-product-desc {
  margin-top: -16px;
  font-size: 12px;
  color: #757575;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Styles for "Add to Cart" button */
.home-add-to-cart-btn {
  width: 100%;
  padding: 8px;
  background-color: #CF142B;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.home-add-to-cart-btn:hover {
  background-color: #CF142B;
}
.similar-products-container {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Align items horizontally */
  margin-bottom: 20px; /* Adjust margin as needed */
}
.collection-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333; 
  position: relative; 
}

.collection-title::after {
  content: '';
  display: block;
  width: 50px; 
  height: 2px; 
  background-color: #333; 
  position: absolute; 
  bottom: -10px;
  left: 50%; 
  transform: translateX(-50%); 
}
.pagination-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.pagination-buttons button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.pagination-buttons button:hover {
  color: #555;
}

.pagination-buttons button.prev-arrow::before,
.pagination-buttons button.next-arrow::after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #333;
  border-right: 2px solid #333;
  transform: rotate(45deg);
  margin-right: 5px;
}

.pagination-buttons button.prev-arrow::before {
  transform: rotate(-135deg);
}

.pagination-buttons button.next-arrow::after {
  margin-left: 5px;
}

.product-section-container {
  overflow-x: auto; 
  white-space: nowrap; 
  margin-bottom: 20px; 
  position: relative; 
}

.prev-arrow,
.next-arrow {
  position: absolute;
  top: 50%; 
  transform: translateY(-50%); 
  background-color: rgba(137, 132, 132, 0.5); 
  cursor: pointer;
  width: 28px; 
  height: 39px;
  border-radius: 50%;
  color: #fff; 
}

.prev-arrow {
  left: 0px; 
}

.next-arrow {
  right: 0px; 
}
.next-arrow svg{
  position: absolute;
  top: 12px;
  right:10px;
}
.prev-arrow svg{
  position: absolute;
  top: 12px;
  left:10px;
}

.prev-arrow:hover,
.next-arrow:hover {
  background-color: #CF142B;
}
/* Media query for responsiveness */
@media (max-width: 768px) {
  .home-product-card {
    width: calc(50% - 20px); /* 50% width with 20px spacing between cards on smaller screens */
  }
}