.amazon-registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  margin: 7% auto;
}

.amazon-registration-box {
  width: 350px;
  padding: 20px;
  background-color: #fff;
}

.amazon-registration-box h2 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 3px;
  background-color: #CF142B;
  color: #111;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background-color: #CF142B;
}

hr {
  margin: 20px 0;
}

p {
  margin-bottom: 10px;
}

a {
  color: #0066c0;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.error-message {
  color: #c00;
  font-size: 12px;
  margin-top: 5px;
}
