/* ProductDetailsTables.css */

.product-details-tables {
    display: flex;
    justify-content: space-between;
    background-color: white;
  }
  
  .technical-details,
  .additional-info {
    flex: 1;
    margin-right: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table td {
    padding: 8px 12px;
    border-bottom: 1px solid #ddd;
  }
  .right{
      background-color: white;
  }
  .left{
      background-color: #e5e7e7;
  }
  table tr:last-child td {
    border-bottom: none;
  }
  
  h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  