.menu-container {
  position: relative;
  /* background-color: #000; */
  margin-left: 45%;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* height: 50px; */
  display: flex;
  align-items: center;
}

.menu-item {
  display: inline-block;
  position: relative;
  margin-left: 15%;
  flex-grow: 1; /* Evenly space menu items */
}

.menu-link {
  display: block;
  padding: 13px;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 0px;
  position: relative;
  font-size: 1rem;
}

.menu-link:hover {
  /* background-color: #f4f4f4; */
  /* color: #232f3e; */
}

.menu-link:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: #CF142B;
}
.mega-menu{
  display: flex;
  justify-content: space-evenly;
}

.submenu-container {
  position: absolute;
  top: 100%;
  left: 0px;
  display: none;
  min-width: 700px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(-10px);
}

.menu-item:hover .submenu-container {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

.submenu {
  padding: 10px;
}

.submenu p {
  margin: 0;
  padding: 5px 0;
  cursor: pointer;
  font-size: 1rem;
  color: #CF142B;
}
.submenu ul{
  padding: 0;
}

.submenu p:hover {
  color: #CF142B;
}

.childmenu {
  list-style-type: none;
  padding: 5px 0;
}
.childmenu p{
  color: #000;
}