/* Styles for each filter category */
.filter-category {
    margin-bottom: 20px;
margin-left: 25%;  }
  
  /* Styles for filter category titles */
  .filter-category h6 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Styles for filter checkboxes */
  .filter-category input[type="checkbox"] {
    margin-left: -48%;
  }
  
  /* Styles for filter labels */
  .filter-category label {
    margin-left: -45%;
    font-weight: 300;
  }
  