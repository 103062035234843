/* SingleProductView.css */

.s-container {
  padding: 20px;
  background-color: white;
}

.single-product-image img {
  max-width: 100%;
  height: 35em;
  width: 100%;
  object-fit: contain;
}

.vertical-thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thumbnail-image {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 10px;
  cursor: pointer;
}

.offer-txt {
  background-color: #CC0C39;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  margin-bottom: 10px;
}

.offer-val {
  color: #CC0C39;
  font-weight: bold;
  font-size: 20px;
}

.a-price-symbol,
.a-price-whole {
  font-weight: bold;
  font-size: 20px;
  margin-left: 5px;
}

.og-text,
.og-price {
  font-size: 10px;
}


/* Card */
.card {
  border: 1px solid #ddd; /* Use Amazon's card border color */
  padding: 15px; /* Increase padding for better spacing */
  background-color: white; /* White background for the card */
}

/* Product Sizes */
.product-sizes {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.size-box {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.size-box:hover {
  background-color: #CF142B;
}

.size-box:active {
  background-color: #CF142B; /* Apply the same background color as on hover */
}

.size {
  font-size: 14px;
  color: #333;
}
.stock{
  color:#007600!important;
}
.size-span{
      font-size: 12px !important;
    line-height: 16px !important;
}
@media (max-width: 768px) {
  .single-product-image {
    text-align: center;
  }
  
  .vertical-thumbnail {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .thumbnail-image {
    margin-right: 10px;
    margin-bottom: 0;
  }
  
  .offer-val,
  .a-price-symbol,
  .a-price-whole {
    font-size: 16px;
  }
  
  .og-text,
  .og-price {
    font-size: 8px;
  }
  
  .card {
    margin-top: 20px;
  }
}


.buttons-container {
  margin-top: 20px; /* Add margin to separate buttons from other content */
  display: flex; /* Use flexbox for button alignment */
  flex-direction: column; /* Arrange buttons vertically */
}

/* Add to Cart Button */
.add-to-cart-btn,.buy-now-btn {
  padding: 10px 20px; /* Adjust padding for better button size */
  background-color: #CF142B; /* Use Amazon's button background color */
  color: #111; /* Use dark color for button text */
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s; /* Add transition effect */
}


/* Hover Effect */
.add-to-cart-btn:hover,.buy-now-btn:hover {
  background-color: #f9b60d; /* Darken the background color on hover */
  color: #111; /* Use dark color for button text */
}


/* Add to Cart Button Specific Styling */
.add-to-cart-btn {
  margin-bottom: 10px; /* Add margin below Add to Cart button */
}

/* Secure Transaction Container */
.secure-transaction {
  padding-top: 3px;
  display: flex; /* Use flexbox for icon and text alignment */
  align-items: center; /* Center items vertically */
}

/* Lock Icon */
.lock-icon {
  width: 8px; /* Set width of lock icon */
  height: 10px; /* Set height of lock icon */
  margin-right: 8px; /* Add margin to separate icon from text */
}

/* Secure Text */
.secure-text {
  font-size: 8px; /* Set font size */
  font-weight: bold; /* Set font weight */
  color:#007600!important;
}


.btn{
  color: rgb(70, 180, 249);
}
.btn-text{
  border-right: 1px solid rgb(70, 180, 249);
}
/* SingleProductView.css */
.add-to-wishlist{
  padding: 10px 20px; /* Adjust padding for better button size */
  background-color: #CF142B; /* Use Amazon's button background color */
  color: #111; /* Use dark color for button text */
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s; /* Add transition effect */
}
.product-color{
  display: flex;
  flex-wrap: wrap;
  border-radius: 50%;
}

.color-box{
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.color-box:hover {
  background-color: #CF142B;
}

.color-box:active {
  background-color: #CF142B; /* Apply the same background color as on hover */
}
/* CSS for product color circles */
.color-circle {
  display: inline-block;
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  border-radius: 50%; /* Makes the shape round */
  margin-right: 10px; /* Adds space between circles */
  border: 1px solid #ccc; /* Add border for better visibility */
}

/* CSS for color name */
.size {
  font-size: 14px;
}
.selected {
  background-color: #CF142B; /* Set the background color for the selected size */
  color: #fff; /* Set the text color for better visibility */
}
.product-info{
  color: #CF142B; 
}
