/* Styles for Wishlist Component */

.wishlist-container {
    padding: 16px;
  }
  
  .product-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .product-media {
    height: 250px;
    object-fit: cover;
  }
  
  .product-name {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .product-price {
    font-weight: bold;
    color: #388e3c; /* Myntra's green color */
  }
  
  .no-products-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 0;
  }
  
  .no-products-image {
    margin-bottom: 24px;
    /* width: 50%;
    max-width: 400px; */
  }
  
  .add-items-button {
    margin-top: 24px;
    padding: 12px 32px;
    font-size: 1rem;
    font-weight: bold;
    width: 20%;
    background-color: #CF142B !important;
  }
  
  .login-message {
    text-align: center;
    padding: 16px;
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 16px;
  }
  
  .login-message > * {
    margin-bottom: 8px;
  }
  