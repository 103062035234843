/* ProductList.css */

/* Container for the entire product list */
.product-list-container {
  display: flex;
}

/* Styles for the filters column */
.filters {
  width: 550px; /* Set width for the filters column */
  padding: 20px;
  border-right: 1px solid #f0f0f0; /* Add border to separate the columns */
  background-color: white;
}

/* Styles for the product grid */
.m-product-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

/* Styles for each product card */
.m-product-card {
  width: 240px; /* Increase the width of the product card */
  margin: 10px;
  background-color: #fff;
}

/* Styles for product images */
.m-product-img {
  width: 100%;
  height: 240px; /* Increase the height of the product image */
  object-fit: cover;
}

/* Styles for product details */
.m-product-details {
  padding: 10px;
}

/* Styles for product name */
.m-product-name {
  font-size: 14px;
  font-weight: 500;
}

/* Styles for product price */
.m-product-price {
  margin-top: -16px;
  font-size: 16px;
  font-weight: bold;
  color: #212121;
}

.m-product-desc {
  margin-top: -16px;
  font-size: 12px;
  color: #757575;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Styles for "Add to Cart" button */
.m-add-to-cart-btn {
  width: 100%;
  padding: 8px;
  background-color: #CF142B;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.m-add-to-cart-btn:hover {
  background-color: #CF142B;
}
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.pagination-button:hover {
  background-color: #45a049;
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}


/* Media query for responsiveness */
@media (max-width: 768px) {
  .product-list-container {
    flex-direction: column; /* Stack items vertically on small screens */
  }

  .m-product-card {
    width: calc(50% - 20px); /* 50% width with spacing between cards on small screens */
  }
}
  