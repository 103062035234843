/* .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader__spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #fe6700;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
   */
   /* HTML: <div class="loader"></div> */
   .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
.loader__spinner {

  --c:no-repeat linear-gradient(orange 0 0);
  background: 
    var(--c),var(--c),var(--c),
    var(--c),var(--c),var(--c),
    var(--c),var(--c),var(--c);
  background-size: 16px 16px;
  animation: 
    l32-1 1s infinite,
    l32-2 1s infinite;
}
@keyframes l32-1 {
  0%,100% {width:45px;height: 45px}
  35%,65% {width:65px;height: 65px}
}
@keyframes l32-2 {
  0%,40%  {background-position: 0 0,0 50%, 0 100%,50% 100%,100% 100%,100% 50%,100% 0,50% 0,  50% 50% }
  60%,100%{background-position: 0 50%, 0 100%,50% 100%,100% 100%,100% 50%,100% 0,50% 0,0 0,  50% 50% }
}