/* OrderDetailsScreen.css */

.order-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .order-details-header {
    text-align: center;
    margin-bottom: 20px;
  }

  .order-details p{
    margin: 0; /* Remove default margin */
    line-height: 1.5; 
  }
  
  .order-card {
    display: flex;
    margin-bottom: 20px;
    background-color: white;
  }
  
  .product-details,
  .delivery-details {
    flex: 1;
    padding: 20px;
  }
  
  .order-items {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .order-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .order-item-image {
    width: 80px;
    height: auto;
    margin-right: 10px;
  }
  
  .order-item-details {
    flex: 1;
  }
  
  .order-item-name {
    font-size: 16px;
    font-weight: bold;
  }
  
  .order-item-price {
    font-size: 14px;
  }
  
  .order-item-quantity {
    font-size: 14px;
  }
  
  .order-total p {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .order-details h3 {
    margin-bottom: 10px;
  }
  
  .order-status {
    margin-top: 20px;
    text-align: right;
  }
  
  .status-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .delivered {
    background-color: rgb(171, 247, 171);
    color: white;
    width: 30%;
  }
  
  .cancelled {
    background-color: rgb(242, 154, 154);
    color: white;
    width: 30%;

  }
  
  .other {
    background-color: orange;
    color: white;
  }
  /* OrderDetailsScreen.css */

.shipping-address p {
    margin: 0; /* Remove default margin */
    line-height: 1.5; /* Adjust line height */
    font-size: 14px; /* Adjust font size */
  }
  
  .shipping-address p:first-child {
    margin-top: 10px; /* Add top margin for the first paragraph */
  }
  
  .shipping-address p:last-child {
    margin-bottom: 10px; /* Add bottom margin for the last paragraph */
  }
  