.customer-review {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .review-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .user-avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .user-info {
    flex: 1;
  }
  
  .user-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .review-date {
    color: #666;
  }
  
  .rating {
    color: orange;
  }
  
  .star-filled,
  .star-empty {
    font-size: 20px;
  }
  
  .star-empty {
    color: #ddd;
    margin-right: 2px;
  }
  .review{
      padding-top: 5px;
  }
  