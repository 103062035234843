.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    margin: 7% auto;
  }
  
  .login-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
  }
  
  .login-box h2 {
    margin-top: 0;
  }
  
  .login-box p {
    margin-bottom: 20px;
  }
  
  .otpinput {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    background-color: #CF142B;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #CF142B;
  }
  
  .resend-otp {
    margin-top: 20px;
  }
  
  .resend-otp p {
    margin-bottom: 10px;
  }
  