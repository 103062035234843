.banner-slideshow-container {
  width: 100%; 
  max-width: 100%; 
  overflow: hidden; 
  /* margin-top: -17px; */
}

.banner-slideshow-container .slick-slider {
  width: 100%;
}

.banner-slideshow-container .slick-slide img {
  width: 100%; 
  height: auto; 
}
