/* HomeScreen.css */
.container{
  margin-top: -25%;
  position: relative;
}
/* Add spacing between the cards */
.collection-card {
  width: 300px; /* Fixed width for the product card */
  margin: 10px;
  background-color: #fff;
}

/* Style the images inside the cards */
.collection-card img {
  width: 100%; /* Make the images fill the container */
  height: auto; /* Maintain aspect ratio */
}

.collection-card p {
  font-size: 12px;
  font-weight: 500;
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Display ellipsis for overflowing text */
  white-space: nowrap; /* Prevent wrapping of text */
}


