/* Header.css */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #00247D;
  color: #fff;
  z-index: 1000; /* Ensure header stays above other content */
  height: 55px;
}

.cart_contain {
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.cart-count {
  position: absolute;
  top: -13px;
  right: 4px;
}

.header_profile_name {
  font-size: 12px;
}

.header__logo img {
  width: 35px; /* Adjust as needed */
  height: 35px;
}

.header_search {
  display: flex;
  align-items: center; /* Align vertically */
  justify-content: center; /* Center horizontally */
  flex-grow: 1; /* Take remaining space */
  width: 100%;
  max-width: 41%;
  margin: 0 auto;
  
  position: relative;
}

.header_search_input {
  width: 100% !important;
  padding: 10px !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 5px !important;
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #00247D; /* Change icon color */
}

.header__nav {
  display: flex;
  margin-right: 5%;
  /* margin-left: auto; Push nav items to the right */
}
.header__nav_li{
  margin-left: 15%;
}
.header__nav_li:hover{
  text-decoration: none !important;
}
.header__nav span {
  color: #fff;
  text-decoration: none;
}

/* Ensure content starts below the fixed header */
body {
  padding-top: 55px; /* Adjust according to header height */
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: calc(100% + 10px); /* Position exactly below the name */
  right: 0;
  background-color: #f9f9f9;
  min-width: 200px; /* Adjust width as needed */
  border-radius: 5px;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow for better visibility */
}

.dropdown-content span {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #111;
  transition: background-color 0.3s;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .header__search {
    margin-left: 10px; /* Adjust margin for smaller screens */
  }

  .header__nav {
    margin-right: 20px; /* Adjust spacing between nav items */
  }

  .header__nav span {
    margin-right: 10px; /* Adjust spacing between nav items */
  }
}
